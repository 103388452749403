import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import Particles from "react-particles-js"

const slugify = require("slugify");

// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
};

// end of styling

// const params = {
//   particles: {
//     number: {
//       value: 20,
//       density: {
//         enable: false,
//       },
//     },
//     size: {
//       value: 10,
//       random: true,
//       anim: {
//         speed: 4,
//         size_min: 0.3,
//       },
//     },
//     color: {
//       value: "#5526fd",
//     },
//     line_linked: {
//       enable: false,
//     },
//     move: {
//       random: true,
//       speed: 1,
//       direction: "top",
//       out_mode: "out",
//     },
//   },
//   interactivity: {
//     events: {
//       onhover: {
//         enable: true,
//         mode: "bubble",
//       },
//       onclick: {
//         enable: true,
//         mode: "repulse",
//       },
//     },
//     modes: {
//       bubble: {
//         distance: 250,
//         duration: 2,
//         size: 0,
//         opacity: 0,
//       },
//       repulse: {
//         distance: 400,
//         duration: 4,
//       },
//     },
//   },
// }

const CaseStudies = ({ data, location }) => {
  const caseStudy = data.allSanityCaseStudy.edges;
  console.log(caseStudy);
  return (
    <>
      <Layout location={location}>
        <SEO title="Case Studies" description="" />

        <div className="casestudy-section" style={termsContent}>
          {/* <Particles
            params={{ ...params }}
            style={{
              width: "100%",
              position: "fixed",
              height: "100%",
              top: 0,
            }}
          /> */}
          <div className="container">
            <h1 className="text-white text-center pb-5 mb-5">Case Studies</h1>
            <div className="row">
              {caseStudy.map((study, index) => (
                <div className="col-md-3 mb-4">
                  <div className="card mb-4 pt-5 h-100">
                    <Link
                      className="card-link"
                      to={`/case-studies/${slugify(
                        study.node.title.toLowerCase()
                      )}`}
                      aria-label="View Profile"
                    ></Link>
                    <div className="card-image text-center">
                      <img
                        alt={study.node.title}
                        className="mb-3 img-fluid"
                        src={study.node.headshots[0].image.asset.fixed.src}
                        width={180}
                        height={180}
                      />
                    </div>
                    <div className="card-body text-center">
                      <h3 className="card-title mb-3 text-primary-gradient">
                        {study.node.title}
                      </h3>
                      <h6 className="mb-0 text-uppercase">
                        {study.node.logo.title}
                      </h6>
                      <p className="text-muted small text-uppercase">Company</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CaseStudies;

export const caseStudyQuery = graphql`
  query caseStudyQuery {
    allSanityCaseStudy(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          logo {
            id
            title
            logo {
              alt
              asset {
                id
                fixed(width: 200) {
                  src
                }
              }
            }
          }
          headshots {
            id
            image {
              alt
              asset {
                id
                fixed(width: 200, height: 200) {
                  src
                }
              }
            }
          }
          graphImage {
            alt
            asset {
              fluid {
                src
              }
            }
          }
          state1
          state2
          timeFrame
          caseStudyPosition
        }
      }
    }
  }
`;
